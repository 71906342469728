import { Outlet } from "react-router-dom"
import TopNav from "./TopNav"

const Backoffice = () => {
  return (
    <>
      {/* <TopNav /> */}
      <Outlet />


    </>
  )
}

export default Backoffice