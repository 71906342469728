import React, { useState, useRef } from 'react';
import { faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ImageUploadFieldProps {
  onChange: (file: File | null) => void;
  value?: File | null;
  onBlur?: () => void;
}

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({ onChange, value, onBlur }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null); // State to manage the error message
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      setError(null); // Clear any previous error
      onChange(file);
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    } else {
      // Set an error message and clear the file input and image preview
      setError('ต้องเป็นไฟล์นามสกุล JPG, JPEG, PNG, and GIF เท่านั้น.');
      onChange(null);
      setImageUrl(null);
    }
  };

  const removeFile = () => {
    onChange(null);
    setImageUrl(null);
    setError(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
    }
  };

  return (
    <div className="p-5 border-2 border-dashed border-gray-300 rounded-lg text-center transition-all duration-300 ease-in-out cursor-pointer bg-black">
      <FontAwesomeIcon icon={faFileUpload} className="text-white text-5xl mb-3" onClick={() => fileInputRef.current?.click()} />
      <p className="text-white">Click to select an image</p>
      <input
        type="file"
        className="hidden"
        onChange={handleFileChange}
        onBlur={onBlur}
        ref={fileInputRef}
        accept="image/jpeg,image/jpg,image/png,image/gif" // Specify accepted file types
      />
      {imageUrl && (
        <div className="mt-3">
          {/* <img src={imageUrl} alt="Preview" className="max-h-40 mx-auto" /> */}
              <img src={imageUrl} alt="Preview" className="mt-3 lg:max-h-72  max-h-100 w-auto justify-center" />

        </div>
      )}
      {value && (
           <div key={value.name} className="flex justify-between items-center text-black p-2 rounded mt-1 bg-white">
<span className="truncate">{value.name}</span>
 <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-gray-800" onClick={removeFile} />
 </div>
      )}
      {error && (
        <div className="text-red-500 mt-2">{error}</div> // Display the error message
      )}
    </div>
  );
};

export default ImageUploadField;


/////////// original ////////////

// import React, { useState, useRef } from 'react';
// import { faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// interface ImageUploadFieldProps {
//   onChange: (file: File | null) => void;
//   value?: File | null;
//   onBlur?: () => void;
// }

// const ImageUploadField: React.FC<ImageUploadFieldProps> = ({ onChange, value, onBlur }) => {
//   const [imageUrl, setImageUrl] = useState<string | null>(null);
//   const fileInputRef = useRef<HTMLInputElement>(null);

//   const handleClick = () => {
//     fileInputRef.current?.click();
//   };

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files ? e.target.files[0] : null;
//     onChange(file);

//     // Create a URL for the selected file
//     if (file) {
//       const url = URL.createObjectURL(file);
//       setImageUrl(url);
//     } else {
//       setImageUrl(null);
//     }
//   };

//   const removeFile = () => {
//     onChange(null);
//     setImageUrl(null); // Remove the image URL

//     if (fileInputRef.current) {
//       fileInputRef.current.value = '';
//     }

//     // Revoke the object URL to free up resources
//     if (imageUrl) {
//       URL.revokeObjectURL(imageUrl);
//     }
//   };


//   return (
//     <div className="p-5 border-2 border-dashed border-gray-300 rounded-lg text-center transition-all duration-300 ease-in-out cursor-pointer bg-black">
//       <FontAwesomeIcon icon={faFileUpload} className="text-white text-5xl mb-3" onClick={handleClick} />
//       <p className="text-white">เลือกไฟล์สลิป</p>
//       <input
//         type="file"
//         className="hidden"
//         onChange={handleFileChange}
//         onBlur={onBlur}
//         ref={fileInputRef}
//         accept="image/*"
//       />
//       <div className='flex justify-center'>
//       {imageUrl && (
//         <img src={imageUrl} alt="Preview" className="mt-3 lg:max-h-72  max-h-100 w-auto justify-center" />
//       )}
//       </div>
    
//       {value && (
//         <div key={value.name} className="flex justify-between items-center text-black p-2 rounded mt-1 bg-white">
//           <span className="truncate">{value.name}</span>
//           <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-gray-800" onClick={removeFile} />
//         </div>
//       )}
//     </div>
//   );
// };

// export default ImageUploadField;
