import { useState, useEffect, useRef } from 'react';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { GetallBank, RegisterSubmit, SubmitOTP } from '../../hooks/Register';
import { RegisterDetailTeyps } from '../../Types/RegisterTypes';
import { GlobalConfig } from '../../hooks/Config';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { OTPDetailTeyps } from '../../Types/OtpType';
import { Toast } from 'primereact/toast';
import ReCAPTCHA from "react-google-recaptcha";
import LogoLnw from "../../Images/magawin_logo_2.png"
// import { useLocation } from 'react-router-dom';
import { RenderAgent } from '../../hooks/CheckAgent';



interface bankType {
    initial: string;
    name: string;
}

const Register = () => {
    const [bank, setBank] = useState<bankType[]>([]);
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [newuername, setNewusername] = useState();
    const toast = useRef<Toast>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptchacode, setRecaptchacode] = useState<string | null>();
    const [accessToken, setAccessToken] = useState<string>();
    const [agentName, setAgentName] = useState<string>();
    // const [adviserName, setAdviserName] = useState<string>(GlobalConfig.AGENT);
    const { af } = useParams();
    // console.log("AG Name:", agentName);


    // const goBack = () => {
    //     navigate('/');
    // };

    const linktosupport = () => {
        window.open(GlobalConfig.LINESUPPORT, '_blank', 'noopener,noreferrer');
    };

    const { register, handleSubmit, formState: { errors }, control } = useForm({
        defaultValues: {
            firstname: '',
            lastname: '',
            password: '',
            mobile: '',
            lineId: '',
            adviser: 'ufulnw311a',
            bankName: '',
            acctNo: '',
            recaptcha: recaptchacode,
        }
    });

    const { register: register2, handleSubmit: handleSubmit2 } = useForm<OTPDetailTeyps>({
        defaultValues: {
            username: newuername,
            otp: '',
        }
    });

    const fetchBank = async () => {
        const resBank = await GetallBank();
        setBank(resBank);
    }


    const checkAffRender = async (af: string | undefined) => {
        const ResRender = await RenderAgent(af);
        // console.log("rest Render:", ResRender)
        setAgentName(ResRender.agentUsername);
    };


    useEffect(() => {
        checkAffRender(af);
        fetchBank();
    }, []);


    const submitRegister: SubmitHandler<RegisterDetailTeyps> = async (data) => {
        try {
            const submissionData = {
                ...data,
                recaptcha: recaptchacode,
                adviser: 'ufulnw311a'

            };

            const resRegis = await RegisterSubmit(submissionData);
            // console.log("resRegis", resRegis);
            // console.log("Submit Data:", data);
            // console.log("submissionData Data:", submissionData);
            if (resRegis.success === true) {
                setVisible(true);
                setNewusername(resRegis.username);
                setAccessToken(resRegis.accessToken);

            } else {

            }
        } catch (error) {
            console.error('An error occurred during registration', error);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: `เกิดข้อผิดพลาดกรุณาตรวจสอบขอมูลให้ถูกต้อง`, life: 3000 });

        }
    };

    const onReCAPTCHAChange = (captchaCode: string | null) => {
        setRecaptchacode(captchaCode);
        if (!captchaCode === null) {
            toast.current?.show({ severity: 'error', summary: 'สำเร็จ', detail: "กรุณากรอก Captca", life: 3000 });
        }
    };

    const submitOTP: SubmitHandler<OTPDetailTeyps> = async (data) => {
        const resOTP = await SubmitOTP(data, accessToken);
        // console.log("OTP REs",resOTP);
        if (resOTP.success === true) {
            toast.current?.show({ severity: 'success', summary: 'สำเร็จ', detail: resOTP.message, life: 3000 });
            navigate('/');
        } else {
            toast.current?.show({ severity: 'error', summary: 'Error Message', detail: 'OTP verification failed', life: 3000 });
        }
    };



    return (
        <>
            <Toast ref={toast} />
            <main className='p-5 h-screen flex flex-col justify-center lg:h-screen lg:items-center lg:my-0 my-32'>
                <form onSubmit={handleSubmit(submitRegister)}>
                    <div className='bg-white drop-shadow-xl lg:w-[550px] rounded-xl flex flex-col items-center px-2 py-4'>
                        <Image src={LogoLnw} width='250' />
                        <Divider />
                        <div className='lg:grid lg:grid-cols-2 lg:gap-2 lg:w-full lg:px-8 w-80'>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="name" className='text-yellow-900 text-sm'>ชื่อ</label>
                                <InputText placeholder='กรุณากรอกชื่อ' className="px-3 py-2"
                                    {...register("firstname", { required: true })}
                                />

                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="surname" className='text-yellow-900 text-sm'>นามสกุล</label>
                                <InputText placeholder='กรุณากรอกนามสกุล' className="px-3 py-2"
                                    {...register("lastname", { required: true })}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="phone" className='text-yellow-900 text-sm'>เบอร์โทร</label>
                                <InputText placeholder='เบอร์โทรศัพท์' className="px-3 py-2"
                                    {...register("mobile", { required: true })}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="lineId" className='text-yellow-900 text-sm'>Line ID</label>
                                <InputText placeholder='หากไม่มีให้ใส่เบอร์โทร' className="px-3 py-2"
                                    {...register('lineId', {
                                        required: true
                                    })}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="referral" className='text-yellow-900 text-sm'>ผู้แนะนำ</label>
                                <InputText placeholder='ผู้แนะนำ' className="px-3 py-2" disabled value='ufulnw311a'
                                />
                            </div>
                        </div>
                        <Divider />
                        <p className='text-yellow-900 text-lg underline mb-4'>ข้อมูลบัญชี</p>
                        <div className='lg:grid lg:grid-cols-2 lg:gap-2 lg:w-full lg:px-8 w-80'>

                            <div className="flex flex-col gap-2">
                                <label htmlFor="password" className='text-yellow-900 text-sm'>รหัสผ่าน</label>
                                <InputText placeholder='รหัสผ่านเช่น Lnw12345@' className="px-3 py-2"
                                    {...register('password', {
                                        required: true,
                                        minLength: {
                                            value: 7,
                                            message: "ความยาวอย่างน้อย 7 ตัวอักษร"
                                        },
                                        pattern: {
                                            value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{7,}$/,
                                            message: "ต้องประกอบด้วยตัวพิมพ์ใหญ่ 1 ตัว ตัวเลข 1 ตัว อักขระพิเศษ 1 ตัว เช่น Lnw12345@' "
                                        }
                                    })}
                                />
                                {errors.password && <span className="text-red-500">{errors.password.message}</span>}
                            </div>
                            <div className="flex flex-col justify-content-center">
                                <label htmlFor="amount" className='text-yellow-900 text-sm'>เลือกโปรโมชั่น</label>
                                <Controller
                                    name="bankName"
                                    control={control}
                                    render={({ field }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={bank} // Use your promotions data as options
                                            optionLabel="name" // Display the promotion name
                                            optionValue="name" // Send the promotion ID as the value
                                            placeholder="เลือกธนาคาร"
                                            className='mt-2'
                                            pt={{
                                                input: { className: 'w-full py-2.5 px-2' },
                                            }}
                                        />
                                    )}
                                />
                                {errors.bankName && <span className="text-red-500">{errors.bankName.message}</span>}
                            </div>


                            <div className="flex flex-col gap-2">
                                <label htmlFor="accountNumber" className='text-yellow-900 text-sm'>หมายเลขบัญชี</label>
                                <InputText placeholder='หมายเลขบัญชี 10  หลัก' className="px-3 py-2"
                                    {...register('acctNo', {
                                        required: true,
                                        minLength: {
                                            value: 10,
                                            message: "หมายเลขบัญชี 10 หลัก"
                                        },
                                    })}
                                />
                                {errors.acctNo && <span className="text-red-500">{errors.acctNo.message}</span>}
                            </div>
                        </div>
                        <div className=' my-2 flex flex-col gap-2'>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6Ld4mB8TAAAAAECXP-18qwV89_YCGQguYNappGBg"
                                onChange={onReCAPTCHAChange}

                            />
                            <p className='text-center lg:text-xs text-gray-500'>กรุณาเช็คถูกเพื่อยืนยันว่าท่านไม่ใช่ระบบอัติโนมัติ</p>
                            <Button label='สมัครสมาชิก' size='small' className='bg-yellow-900 border-none' rounded type='submit' disabled={!recaptchacode} />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='text-black text-center'>หรือ</p>
                            <p className='text-black text-center'>มีบัญชีอยู่แล้ว <span className='text-red-500 underline' onClick={() => navigate('/')}>เข้าสู่ระบบ</span></p>
                            <Button label='ติดต่อเรา' size='small' rounded severity='success' onClick={linktosupport} />
                        </div>
                    </div>
                </form>
                {/* <div className='flex gap-2 justify-end' onClick={goBack} >
                    <h2 className='mt-1'><FontAwesomeIcon icon={faArrowLeft} className='text-2xl ' /></h2>
                    <h2 className='mt-1'>กลับหน้าหลัก</h2>
                </div> */}

                <div className="card flex justify-content-center">
                    <Dialog header="ยืนยันOTP" visible={visible} style={{ width: '80vw' }} onHide={() => setVisible(false)}>
                        <form onSubmit={handleSubmit2(submitOTP)} >
                            <div className='flex flex-col gap-2 mt-4'>
                                <InputText placeholder='username' defaultValue={newuername}
                                    {...register2('username')}
                                />
                                <InputText placeholder='OTP'
                                    {...register2('otp')}
                                />
                                <Button label='ยืนยัน OTP' size='small' type='submit' />
                            </div>
                        </form>
                    </Dialog>
                </div>

            </main>
        </>
    );
}

export default Register;
