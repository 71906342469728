import Dashboard from "./page/memberdashboard/Dashboard";
import Deposit from "./page/memberdashboard/Deposit";
import Withdraw from "./page/memberdashboard/Withdraw";
import Promotion from "./page/memberdashboard/Promotion";

const authRoute = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/dashboard/deposit", element: <Deposit /> },
    { path: "/dashboard/withdraw", element: <Withdraw /> },
    { path: "/dashboard/promotion", element: <Promotion /> },

  
  ];
  
  export default authRoute;
  